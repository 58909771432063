<template lang="">
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 532px;"> <div
  class="maincontentinner" style="opacity: 1; left: 0px; transition: all 0.5s ease 0s;" >
  <div class="mt_wrap"> <h1 class="ttl_1">{{ conts.title }}<span></span></h1> <nav
  class="breadcrumb_wrap"> <ul class="breadcrumb"> <li> <router-link :to="{name:
  'Dashboard'}"> {{ conts.breadcrumb.dashboard }} </router-link> </li> <li> <router-link
  to="/admin/user"> {{ conts.breadcrumb.facility_staff }} </router-link> </li> <li><span>
  {{ conts.breadcrumb.details }} </span></li> </ul> </nav> </div> <Spinner v-if="spinner"
  /> <!--modal--> <SuccessMessage v-if="successMessage" /> <div class="toggle_wrap"> <div
  class="toggle_dsn"> <span class="modeon"> {{ conts.breadcrumb.view }}</span>
  <router-link :to="{ name: 'User Edit', params: { id: $route.params.id }, }" >
  {{ conts.breadcrumb.edit }}</router-link > </div> <!--toggle_dsn--> </div> <div
  class="command_wrap command_bottom"> <div class="leftarea btn_wrap"> <div
  class="return_link"> <router-link to="/admin/user">
  {{ conts.staff_list }} </router-link> </div> <div></div> </div> <!--leftarea--> <div
  class="rightarea"> <div></div> <div class="commandtext_wrap"> <div
  class="commandtextlist"></div> </div> <!--commandtext_wrap--> </div> <!--rightarea-->
  </div> <!--command_wrap--> <div class="contentout_wrap"> <div class="content_wrap
  twocolumn"> <!--box_wrap--> <div class="box_wrap left"> <h2 class="ttl_2">{{
    conts.sub_title
  }}</h2> <div class="scr_outwrap"> <div class="scr_wrap"> <table class="formtable">
  <tbody> <tr> <th class="wid_200px">{{ conts.grid.name }}</th> <td> <div
  class="form_outwrap"> <div class="wrap wid_100per m_b0"> <div class="in_wrap"> <p
  class="f_500"> <ruby> {{ showUser.last_name }} {{ showUser.first_name }} <rt
  style="color: #999" >{{ showUser.last_name_kana }} {{ showUser.first_name_kana }}</rt >
  </ruby> </p> </div> </div> </div> </td> </tr> <tr> <th class="wid_200px">{{
    conts.grid.user_code
  }}</th> <td> <div class="form_outwrap"> <div class="wrap wid_100per p_r0 m_b0"> <div
  class="in_wrap"> <span class="en fw_400"> {{ showUser.user_code }}</span> </div> </div>
  </div> </td> </tr> <tr> <th class="wid_200px">{{ conts.grid.address }}</th> <td> <div
  class="form_outwrap"> <div class="wrap wid_100per p_r0 m_b0"> <div class="in_wrap"> <p>
  <small class="small_header" >{{ conts.grid.mail_address }}</small ><span class="en
  fw_400" >{{ showUser.email }}</span > </p> <p> <small class="small_header">{{
    conts.grid.tel
  }}</small ><span class="en fw_400">{{ showUser.phone }}</span> </p> </div> </div> </div>
  </td> </tr> <tr > <th class="wid_200px">{{ conts.grid.section }}</th> <td> <div
  class="form_outwrap"> <div class="wrap wid_100per p_r0 m_b0"> <div class="in_wrap">
  {{ showUser.group ? showUser.group.name : "" }}
  </div> </div> </div> </td> </tr> <!-- <tr> <th class="wid_200px">{{
    conts.grid.group
  }}</th> <td> <div class="form_outwrap"> <div class="wrap wid_100per p_r0 m_b0"> <div
  class="in_wrap">
  {{ showUser.group ? showUser.group.name : "" }}

  </div> </div> </div> </td> </tr> --> <tr> <th class="wid_200px">{{
    conts.grid.position
  }}</th> <td> <div class="form_outwrap"> <div class="wrap wid_100per p_r0 m_b0"> <div
  class="in_wrap">
  {{ showUser.group ? showUser.group.group_role : "" }}
  </div> </div> </div> </td> </tr> <tr> <th class="wid_200px">{{
    conts.grid.introduce
  }}</th> <td> <div class="form_outwrap"> <div class="wrap wid_100per p_r0 m_b0"> <div
  class="in_wrap"> </div> </div> </div> </td> </tr> </tbody> </table> <!--formtable-->
  </div> <!--scr_wrap--> </div> <!--scr_outwrap--> </div> <div class="box_wrap right">
  <div class="form_outwrap"> <div class="wrap wid_100per p_r0"> <div><p class="ttl_3">{{
    conts.grid.authority
  }}</p></div> <div class="in_wrap"> <p>{{ isAdmin(showUser.is_admin) }}</p> </div> </div>
  <div class="wrap wid_100per p_r0"> <div><p class="ttl_3">{{ conts.grid.sex }}</p></div>
  <div class="in_wrap"> <p>{{ showUser.sex }}</p> </div> </div> <div class="wrap
  wid_100per p_r0"> <div><p class="ttl_3">{{ conts.grid.hire_date }}</p></div> <div
  class="in_wrap"> <p>{{ showUser.here_date }}</p> </div> </div> <div class="wrap
  wid_100per p_r0"> <p class="ttl_3">アイキャッチ</p> <div class="in_wrap"> <img
  :src="'data:image/png;base64,'+profilePicture" alt=""> </div> </div>
  </div><!--form_outwrap--> </div> <!--box_wrap--> </div> <!--content_wrap--> </div>
  <!--contentoutwrap--> </div> <!--maincontentinner--> </div>
</template>
<script>
import CONST from "../../../const/control_panel/user";
import { mapState, mapGetters, mapActions } from "vuex";
import Spinner from "../../../components/control_panel/Spinner.vue";
import SuccessMessage from "../../../components/control_panel/SuccessMessage.vue";

import $ from "jquery";

export default {
  data() {
    return {
      conts: CONST.SHOW,
      showUser: {},
      profilePicture: {},
    };
  },
  created: function (event) {
    this.getUser(this.$route.params.id);
    this.getPicture(this.$route.params.id);
  },
  components: {
    SuccessMessage,
    Spinner,
  },
  methods: {
    ...mapActions("user", ["getUser"]),
    ...mapActions("file", ["getPicture"]),

    isAdmin(isAdmin) {
      if (isAdmin) {
        return "管理者";
      } else {
        return "ユーザー";
      }
    },
  },
  computed: {
    ...mapState("user", ["viewUser"]),
    ...mapState("common", ["successMessage", "spinner"]),
    ...mapState("file", ["picture"]),
  },
  watch: {
    viewUser(val) {
      console.log(val);
      this.showUser = val;
    },
    picture(img) {
      this.profilePicture = img != "" ? img[0].base64 : "";
    },
  },
  updated: function (event) {},
  mounted: function (event) {},
  errorCaptured: function (err, instance, info) {
    return false;
  },
};
</script>
<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
</style>
